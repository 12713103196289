.footer {
  flex-shrink: 0;
  padding-bottom: 1em;
  padding-top: 2em;
  text-align: center;
}

p {
  margin-bottom: 0px;
}
