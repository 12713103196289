.table1 {
  font-size: 22px;
  font-weight: 500;
  margin-top: 3rem;
}

.table-centered {
  display: grid;
  place-content: center;
  text-align: center;
  min-height: 50vh;
}

.img-table {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.btn.btn-link.nomargin {
  padding: 0px !important;
  margin: 0px !important;
  text-decoration: none;
  color: #5b6688;
  font-size: 14px !important;
}

.tableH4 {
  font-family: "Montserrat";
  font-size: 22px;
  padding-left: 12px;
}

.perPageButton > button {
  border: 1px solid rgba(114, 117, 126, 0.25);
  border-radius: 4px;

  color: #2b2b36;
  font-size: 16px;
  font-weight: 500;
}

a.page-link > span {
  pointer-events: none;
}

ul.pagination {
  padding: 0;
  margin-left: 6px !important;
}

li.page-item {
  padding: 0;
}

.page-link {
  font-size: 18px !important;
  font-weight: 500;
  border: none;
  color: #2b2b36;
  border-radius: 4px;
}

li.page-item.active > .page-link {
  background-color: rgba(29, 87, 208, 0.1) !important;
  color: #1d57d0;
}

.perPageButton {
  width: 70px;
  height: 40px;
  color: #2b2b36 !important;
}

.pRows {
  font-size: 16px;
  display: inline-block;
  margin-right: 1rem;
}

.divRows {
  min-width: 200px;
  margin-left: auto;
}

ul.pagination.pagination-sm {
  margin-left: 1rem;
}

.first.page-item > .page-link {
  padding-left: 32px !important;
}

.first::after {
  position: relative;
  bottom: 29px;

  z-index: 10;

  content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' transform='rotate(180)' x='0px' y='0px' width='27px' viewBox='0 0 42.7 36' style='enable-background:new 0 0 42.7 36;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;opacity:0.5;%7D .st1%7Bdisplay:inline;%7D .st2%7Bfill:%231D1D1B;stroke:%23000000;stroke-miterlimit:10;%7D .st3%7Bfill:%2372757E;%7D%0A%3C/style%3E%3Cg class='st0'%3E%3Cg id='Layer_1' class='st1'%3E%3Cimage style='overflow:visible;' width='2880' height='3348' xlink:href='D:%5CUsers%5CGeorgy%5CDownloads%5CCustomer - Permissions _ Edit@2x.png' transform='matrix(1 0 0 1 -1004 -429.3333)'%3E%3C/image%3E%3Cpath class='st2' d='M16.6,11'/%3E%3C/g%3E%3Cg id='Layer_2'%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cpolygon class='st3' points='11.9,7.3 9.5,9.7 18.8,19 9.5,28.2 12,30.6 23.3,19 '/%3E%3Cpolygon class='st3' points='28.8,7.3 28.8,30.6 32.6,30.6 32.5,7.3 '/%3E%3C/g%3E%3C/svg%3E%0A");
}

.prev.page-item > .page-link {
  padding-left: 22px !important;
}

.prev::after {
  position: relative;
  bottom: 26px;
  left: 2px;
  z-index: 10;

  content: url("data:image/svg+xml,%3C!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' transform='rotate(180)' x='0px' y='0px' width='11px' height='15px' viewBox='0 0 13.8 23.3' style='overflow:visible;enable-background:new 0 0 13.8 23.3;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2372757E;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Layer_2_2_'%3E%3Cpolygon class='st0' points='2.5,0 0,2.4 9.3,11.8 0,20.9 2.5,23.3 13.8,11.8 '/%3E%3C/g%3E%3C/svg%3E%0A");
}

.next.page-item > .page-link {
  padding-right: 22px !important;
}

.next::after {
  position: relative;
  bottom: 26px;
  left: 50px;
  z-index: 10;

  content: url("data:image/svg+xml,%3C!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'  x='0px' y='0px' width='11px' height='15px' viewBox='0 0 13.8 23.3' style='overflow:visible;enable-background:new 0 0 13.8 23.3;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2372757E;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Layer_2_2_'%3E%3Cpolygon class='st0' points='2.5,0 0,2.4 9.3,11.8 0,20.9 2.5,23.3 13.8,11.8 '/%3E%3C/g%3E%3C/svg%3E%0A");
}

.last.page-item > .page-link {
  padding-right: 32px !important;
}

.last::after {
  position: relative;
  bottom: 30px;
  left: 44px;
  z-index: 10;

  content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='27px' viewBox='0 0 42.7 36' style='enable-background:new 0 0 42.7 36;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bdisplay:none;opacity:0.5;%7D .st1%7Bdisplay:inline;%7D .st2%7Bfill:%231D1D1B;stroke:%23000000;stroke-miterlimit:10;%7D .st3%7Bfill:%2372757E;%7D%0A%3C/style%3E%3Cg class='st0'%3E%3Cg id='Layer_1' class='st1'%3E%3Cimage style='overflow:visible;' width='2880' height='3348' xlink:href='D:%5CUsers%5CGeorgy%5CDownloads%5CCustomer - Permissions _ Edit@2x.png' transform='matrix(1 0 0 1 -1004 -429.3333)'%3E%3C/image%3E%3Cpath class='st2' d='M16.6,11'/%3E%3C/g%3E%3Cg id='Layer_2'%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cpolygon class='st3' points='11.9,7.3 9.5,9.7 18.8,19 9.5,28.2 12,30.6 23.3,19 '/%3E%3Cpolygon class='st3' points='28.8,7.3 28.8,30.6 32.6,30.6 32.5,7.3 '/%3E%3C/g%3E%3C/svg%3E%0A");
}

.tableCheckbox,
.tableCheckbox:hover {
  border: none !important;
}

.tableCheckboxUnChecked {
  border: 1px solid rgba(114, 117, 126, 0.6);
  border-radius: 2px;
  color: #f4f7fd;
}

.multiselect {
  font-size: 14px;
  color: #5b6688;
  font-weight: 400;
}

.vipTable > tbody > tr {
  border-color: rgba(114, 117, 126, 0.1) !important;

  &:hover {
    background-color: rgba(29, 87, 208, 0.08);
  }
}

.btn-toolbar {
  padding-right: 8px;
  max-height: 40px;
}

.cursorPointer {
  &:hover {
    cursor: pointer;
  }
}
