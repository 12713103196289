.wizard__offer {
  margin: 50px 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  min-width: 1000px;
  @media (max-width: 600px) {
    padding-top: 0px;
    min-width: 400px;
    // flex-wrap: wrap;
  }

  &-right {
    margin: 0 auto;
    max-width: 600px;
  }
  &-post {
    display: flex;
    flex-direction: column;
    &-img {
      padding-top: 58px;
      margin: 0 auto;
    }
    &-box {
      padding-top: 76px;
      padding-bottom: 76px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-text {
        line-height: 1.5em;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.wizard__offer-post-box-heading {
  margin-bottom: 0.4em;
  color: #2b2b36;
  font-family: Montserrat;
  font-size: 3.4em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 52px;
  text-align: center;
  padding-top: 50px;
}

.wizard5-sites {
  display: flex;
  margin-left: 3em;
  margin-bottom: 3em;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 250px;
  width: 250px;
  border: 1px dashed rgba(114, 117, 126, 0.25);
  border-radius: 4px;
  background-color: #ffffff;
}

.wizard5-info,
.wizard5-infoinfodashed {
  text-align: left;
  margin-left: 3em;
  margin-bottom: 3em;
  padding: 1.8em;
  box-sizing: border-box;
  height: 280px;
  width: 280px;
  border: 1px solid rgba(114, 117, 126, 0.25);
  border-radius: 4px;
  background-color: #ffffff;
}
.wizard5-info {
  display: flex;
  flex-direction: column;
}

.wizard5-infoinfodashed {
  text-align: center;
  border: 1px dashed rgba(114, 117, 126, 0.25);
}

.wizard5__info-heading {
  height: 32px;
  color: #2b2b36;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
}

.wizard5__info-data {
  padding-top: 1.4em;
  flex: 1 0 auto;
}
.wizard5__info-data * {
  color: #5b6688;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  margin: 0;
  margin-top: 0;
}

.button__sites {
  margin-top: 3.2em;
  margin-left: 0 !important;
  width: 100%;
}

.sitesImg {
  margin-top: 3rem;
}
