.wizard__offer {
  margin: 50px 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  min-width: 1000px;
  @media (max-width: 600px) {
    padding-top: 0px;
    min-width: 400px;
    // flex-wrap: wrap;
  }

  &-right {
    margin: 0 auto;
    max-width: 600px;
  }
  &-post {
    display: flex;
    flex-direction: column;
    &-img {
      padding-top: 58px;
      margin: 0 auto;
    }
    &-box {
      padding-top: 76px;
      padding-bottom: 76px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-text {
        line-height: 1.5em;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.button-wizard {
  margin-top: 20px;
  background-color: transparent;
  height: 24px;
  width: 74px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  box-sizing: border-box;
  height: 40px;
  width: 214px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 4px;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  border-radius: 0.3em;
  color: #007bff;
  // display: inline-block;
  padding: 5px 12px;
  cursor: pointer;
}

.status__progress {
  margin-bottom: 1em;
}
