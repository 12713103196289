.box {
  background-color: white;
  width: 80%;
  padding: 30px;
  margin: auto;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
table {
  width: 100%;
  margin: auto;
}
td {
  padding: 10px;
}
.head {
  font-size: 17px;
  font-weight: 550;
}
.bgBox {
  background-color: white;
  /* width: 100%; */
  width: 550px;
  margin: auto;
  padding: 20px;
  color: rgb(97, 97, 97);
  display: flex;
  justify-content: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.tValue {
  border: 1px solid black;
  width: 300px;
  padding: 10px;
  display: inline-block;
  height: 100%;
}
