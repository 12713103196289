h3 {
  font-weight: 600;
}

h6 {
  font-weight: 500;
}

.login {
  max-width: 360px !important;
}
