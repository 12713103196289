.profileTabs > a {
  background: none !important;
  border: none !important;
  font-size: 16px;
  color: inherit;
  line-height: 48px;
  padding: 0;
  margin-right: 24px;
  padding-bottom: 16px !important;
  &[aria-selected="true"] {
    border-bottom: 2px solid #1d57d0 !important;
    font-weight: 500;
    color: inherit !important;
  }
}

.profileTabs {
  border: none !important;
  margin-bottom: 40px !important;
}
