.wizard2__offer {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  // min-width: 800px;
  @media (max-width: 600px) {
    padding-top: 0px;
    // min-width: 400px;
    // flex-wrap: wrap;
  }

  &-right {
    width: 100%;
    // margin:0 auto;
    // min-width: 100%;
    padding: 24px 32px;
    // max-width:800px;
  }
  &-post {
    display: flex;
    flex-direction: column;
    &-img {
      padding-top: 58px;
      margin: 0 auto;
    }
    &-box {
      padding-top: 76px;
      padding-bottom: 76px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-text {
        line-height: 1.5em;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.wizard2__right-heading {
  font-size: 2.2em;
  font-weight: 500;
}

.wizard2__offer-post-box-heading {
  height: 104px;
  // width: 600px;
  color: #2b2b36;
  font-family: Montserrat;
  font-size: 3.4em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 52px;
  text-align: center;
  padding-top: 50px;
}
.button-wizard2 {
  margin-top: 20px;
  background-color: transparent;
  height: 24px;
  width: 74px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  box-sizing: border-box;
  height: 40px;
  width: 214px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 4px;
}

.button__close {
  background-image: url(../pages/NewCustomerWizard/images/button-close.png);
  background-size: 15px 15px;
  background-position: center center;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  width: 24px;
  height: 24px;
}

.wizard2__right-heading-div {
  display: flex;
  justify-content: space-between;
}

.wizard2__right-buttons {
  margin-top: 20px;
  padding-top: 24px;
  border-top: 1px solid rgba(114, 117, 126, 0.1);
  display: flex;
  justify-content: space-between;
}

.button__back {
  height: 40px;
  width: 84px;
  color: #1d57d0;
  border: 1px solid rgba(114, 117, 126, 0.25);
  border-radius: 4px;
  background: transparent;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-left: 0 !important;
}

.button__next {
  height: 40px;
  width: 84px;
  color: #fff;
  border-radius: 4px;
  background-color: #1d57d0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border: none;
  margin-left: 0px !important;
}

.button__viewsite {
  margin-left: 0 !important;
  margin-top: 2.5rem;
  flex-shrink: 0;
  width: 100%;
  color: #1d57d0;
  border: 1px solid rgba(114, 117, 126, 0.25);
  border-radius: 4px;
  background: transparent;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

label {
  margin-bottom: 0 !important;
  font-size: 1.4em;
  color: #5b6688;
  line-height: 26px;
}

.find-address {
  margin-top: 25px;
  height: 40px;
  width: 143px;
  border-radius: 4px;
  background-color: #1d57d0 !important;
  color: #ffffff !important;
  font-family: Roboto;
  font-size: 1.3em !important;
  font-weight: 500 !important;
  border: none !important;
  line-height: 24px;
}

.enter-address-manual {
  height: 24px;
  width: 168px;
  color: #1d57d0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-right: 5px;
  padding-left: 5px;
}
#formGridCheckbox {
  padding-right: 5px;
  padding-left: 5px !important;
}

.form {
  max-width: 320px !important;
}
