.wizard3__offer {
  margin: 50px 0;
  display: flex;
  // align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  min-width: 1000px;
  @media (max-width: 600px) {
    padding-top: 0px;
    min-width: 400px;
    // flex-wrap: wrap;
  }

  &-right {
    padding: 24px 32px;
    width: 100%;
    margin: 0 auto;
    // min-width: 65%;
  }
  &-post {
    display: flex;
    flex-direction: column;
    &-img {
      padding-top: 58px;
      margin: 0 auto;
    }
    &-box {
      padding-top: 76px;
      padding-bottom: 76px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-text {
        line-height: 1.5em;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.wizard3__offer-post-box-heading {
  height: 104px;
  width: 600px;
  color: #2b2b36;
  font-family: Montserrat;
  font-size: 3.4em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 52px;
  text-align: center;
  padding-top: 50px;
}
.button-wizard {
  margin-top: 20px;
  background-color: transparent;
  height: 24px;
  width: 74px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  box-sizing: border-box;
  height: 40px;
  width: 214px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 4px;
}

.wizard3__right-heading-div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(114, 117, 126, 0.1);
  margin-bottom: 1.6em;
}

.wizard3__right-heading {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0.8em;
}

.button__skip {
  height: 40px;
  width: 138px;
  color: #1d57d0;
  border: 1px solid rgba(114, 117, 126, 0.25);
  border-radius: 4px;
  background: transparent;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.table thead th {
  vertical-align: bottom;
  border: none !important;
}

thead th {
  height: 26px;
  color: #5b6688;
  font-family: Roboto;
  font-size: 1.4em;
  letter-spacing: 0;
  line-height: 26px;
}

.groupName {
  height: 26px;
  color: #2b2b36;
  font-family: Roboto;
  font-size: 1.6em;
  letter-spacing: 0;
  line-height: 26px;
}
.userDescription {
  color: #5b6688;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
}

.button__options {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background: transparent;
  border: none;
  background-image: url(../pages/NewCustomerWizard/images/more-options.png);
  background-size: 4px 16px;
  background-repeat: no-repeat;
  background-position: center center;

  &:hover {
    background-color: rgba(29, 87, 208, 0.1);
  }
}

.table td,
.table th {
  vertical-align: middle !important;
}

.text-permissions {
  color: #2b2b36;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 24px;
}
.plus-icon {
  background-image: url(../pages/NewCustomerWizard/images/add-icon-color.png);
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 10px;
}

.button__group {
  box-sizing: border-box;
  height: 40px;
  width: 176px;
  border: 1px solid rgba(114, 117, 126, 0.25);
  border-radius: 4px;
  background: transparent;
  color: #1d57d0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.wizard3__right-p {
  color: #2b2b36;
  font-family: Montserrat;
  font-size: 1.6em;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  padding-left: 0.75rem;
}
