@import "_config.scss";

@import "_wizard1.scss";
@import "_wizard2.scss";
@import "_wizard3.scss";
@import "_wizard4.scss";
@import "_wizard5.scss";
@import "_wizard6.scss";
@import "_wizard7.scss";
@import "_wizard8.scss";

// .body {
//   background-color:$white;
//   background-size: cover;
//   background-position:center center;
//   background-repeat: no-repeat;
//   height:100%;
//   @media(max-width:1024px){
//     height:100%;
//   }

// }
// .container {
//   width: auto;
//   padding-right: 15px;
//   padding-left: 15px;

//   @media(max-width:900px) {

//   }
//   @media(max-width:600px) {
//     margin-right: 0;
//     margin-left: 0;
//   }
//   @media(max-width:370px) {
//     width: 100%!important;
//   }
// }

//temporary solution for hiding the VipSidebar
// #root
//   > div.container-fluid
//   > div
//   > div
//   > div.row
//   > div.col-lg-2.col-md-2.col-sm-2.col-2 {
//   display: none;
// }

.container-wizard {
  max-width: 1140px;
  margin: 0 auto;

  @media (max-width: 720px) {
    max-width: 800px;
  }
}

/* reusable */

.status {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: $blue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 262px;

  @media (max-width: 600px) {
    min-width: 100%;
    display: none;
  }

  &__button {
    margin-top: 20px;
    background-color: transparent;
    height: 24px;
    // width: 74px;
    color: #ffffff;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    box-sizing: border-box;
    height: 40px;
    width: 214px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 4px;
  }

  &__heading {
    font-size: 1.8em !important;
    color: white;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 0.8em;
  }

  &__bottom {
    padding-top: 116px;
    padding: 25px;
  }
  &__progress {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    font-family: Roboto;
    font-size: 14px;
  }
}

.button-primary {
  height: 40px;
  width: 290px;
  border-radius: 4px;
  background-color: #1d57d0;
  color: #ffffff;
  font-family: Roboto;
  font-size: 1.2em;
  font-weight: 500;
  border: none;
  @media (max-width: 624px) {
    width: auto;
  }
}
.button-secondary {
  height: 40px;
  width: 290px;
  color: #1d57d0;
  font-family: Roboto;
  font-size: 1.6em;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border: none;
  background: none;
  @media (max-width: 624px) {
    width: auto;
  }
}

ul {
  padding: 25px;
  margin-top: auto;
  margin-bottom: 1rem;
}

.scrolling-wrapper {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  .thing {
    display: inline-block;
  }
}

/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 1s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  float: right;
  left: 0;
  top: 0;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 35%; /* 100% width */
  text-align: center; /* Centered text/links */
  background-color: rgb(255, 255, 255); /* Black fallback color */
  transition: 1s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  // margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

.whiteFill {
  background-color: rgb(255, 255, 255);
}

.wizard2__menu-li {
  color: white !important;
}

.tarif_label {
  margin-left: 1em;
}

ul * {
  list-style: none;

  /*
padding-top: 16px;*/
}

ul a {
  color: #fff;
  font-size: 1.6em;
}
ul a:hover {
  color: #fff;
  text-decoration: none;
}

ul li .buttonLink > span {
  box-sizing: border-box;
  // height: 32px;
  // width: 32px;
  border: 1px solid white;
  // border: 1px solid #FFFFFF;//

  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: 50%;

  /* Firefox 1-3.6 */
  -moz-border-radius: 50%;
  border-radius: 50%;
  padding: 5.5px 10.5px;
  margin-right: 10px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

ul li .menu-active {
  background-color: rgba(255, 255, 255, 0.16);
  border: solid 1px transparent;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
ul li .menu-done {
  color: transparent;
  background-color: #85c443;
  border: 1px solid transparent;
  background-image: url(../pages/NewCustomerWizard/images/done-icon.png);
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: center;

  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.enter-address-manual {
  width: 100%;
}
.progress {
  display: flex;
  height: 12px !important;
  overflow: hidden;
  line-height: 0;
  border: 1px solid rgba(255, 255, 255, 0.16);
  font-size: 0.75rem;
  background-color: transparent !important;
  border-radius: 6px !important;
}

.progress-bar {
  background-color: #85c443 !important;
  border-radius: 4px;
  height: 8px;
  margin: 1px;
}

.WHITEN {
  background-color: rgb(255, 255, 255);
}

.popupContent {
  padding-left: 3em;
  padding-right: 3em;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.popupContent > fieldset > legend {
  padding-top: 3em;
  margin-bottom: 3em;
}

.popupContent > div > .btn {
  margin-left: 0px !important;
  width: 100%;
  margin-bottom: 1.1em;
}

.buttonLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  /*input has OS specific font-family*/
  color: #fff;
  height: 40px;
  cursor: pointer;
  font-size: 1.1em;
}

.status__top {
  width: 100em;
}

.wizard1__main-post {
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.mainContent input[type="radio"] {
  transform: scale(1.5);
  margin-right: 1em;
  margin-left: 0.3em;
}

#estimatedNumberOfExtensions {
  max-width: 100px;
  margin-bottom: 1em;
}

input[type="text"],
.selector {
 // max-width: 330px;
  margin-bottom: 1em;
}

.underlined {
  padding-bottom: 0.8em;
  margin-bottom: 1em;
  border-bottom: 1px solid rgba(114, 117, 126, 0.1);
}

.narrow {
  max-width: 150px;
}

.asLabel {
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 500;
}

.tableMargin {
  margin-left: 12px;
}

.divMargin {
  margin-left: 25px;
}

.mainContent {
  flex: 1 0 auto;
}

.bottomButtons {
  border-top: 1px solid rgba(114, 117, 126, 0.1);
  padding-top: 30px;
  flex-shrink: 0;
}
