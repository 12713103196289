.wizard1 {
  margin: 50px 0;
  display: flex;

  background-color: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  min-width: 1000px;

  @media (max-width: 600px) {
    padding-top: 0px;
    min-width: 400px;
    // flex-wrap: wrap;
  }

  &__main {
    padding: 24px 32px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    &-heading {
      color: #2b2b36;
      font-family: Montserrat;
      font-size: 3.4em;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 52px;
      text-align: center;
      padding-top: 50px;
      @media (max-width: 900px) {
        line-height: 36px;
      }
    }

    &-post {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    &-img {
      margin: 0 auto;
      margin-top: 58px;
    }

    &-buttons {
      padding-top: 76px;
      padding-bottom: 76px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
