.wizard4__offer {
  margin:50px 0;
  display: flex;
 
  background-color:#FFFFFF;
  box-shadow: 0 2px 24px 0 rgba(0,0,0,0.08);
  border-radius: 4px;
  min-width: 1000px;
  @media(max-width:600px) {
    padding-top:0px;
    min-width: 400px;
    // flex-wrap: wrap;
  }
  
  &-right {
    // padding: 24px 32px;
    // margin:0 auto;
    // min-width: 65%;
    padding: 24px 32px;
    width: 100%;
  }
  &-post {
    display: flex;
    flex-direction: column;
    &-img {
      padding-top:58px;
      margin: 0 auto;
    }
    &-box {
      padding-top:76px;
      padding-bottom:76px;
      display:flex;
      flex-direction: column;
      align-items: center;
      &-text {
        line-height: 1.5em;
        color: rgba(0,0,0,0.6);
      }
    }
  }
}

.wizard4__offer-post-box-heading {
  height: 104px;
  width: 600px;
  color: #2B2B36;
  font-family: Montserrat;
  font-size: 3.4em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 52px;
  text-align: center;
  padding-top: 50px;
}
.button-wizard {
  margin-top:20px;
  background-color: transparent;
  height: 24px;
  width: 74px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  box-sizing: border-box;
  height: 40px;
  width: 214px;
  border: 1px solid rgba(255,255,255,0.25);
  border-radius: 4px;
}


.wizard4__right-heading-div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(114,117,126,0.1);

}

.wizard4__right-heading{
  font-size: 2.2em;
  font-weight: 500;
}


.groupName {
  height: 26px;
  color: #2B2B36;
  font-family: Roboto;
  font-size: 1.6em;
  letter-spacing: 0;
  line-height: 26px;
}
.userDescription {
  color: #5B6688;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
}





.text-permissions {
  color: #2B2B36;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 24px;
}


.button__group{
  box-sizing: border-box;
  height: 40px;
  width: 176px;
  border: 1px solid rgba(114,117,126,0.25);
  border-radius: 4px;
  background:transparent;
  color: #1D57D0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}