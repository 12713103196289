.options_dropdown > button {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background-color: transparent !important;
  border: none;
  background-image: url(../pages/NewCustomerWizard/images/more-options.png);
  background-size: 4px 16px;
  background-repeat: no-repeat;
  background-position: center center;
}

.options_dropdown > button::after {
  display: none;
}
