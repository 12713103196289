/* 
vars
*/
$black: #010000;
$black-font: #2b2b36;
$white: #ffffff;
$lightGrey: #d7d7d7;
$darkGrey: #333;
$red: #fc5156;
$green: #7ec377;
$blue: #1d57d0;
$lightBlue: #56b8f1;

/*
mixins
*/

@mixin translateY($e) {
  -webkit-transform: translateY($e);
  -moz-transform: translateY($e);
  transform: translateY($e);
}

@mixin transition($e) {
  -webkit-transition: $e;
  -moz-transition: $e;
  transition: $e;
}

/* 
Fonts - 
*/

body {
  // background:$white;
  font-family: Roboto, Helvetica, sans-serif;
  color: $black-font;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  font-size: 10px;
  @media (max-width: 900) {
    font-size: 7px;
  }
}
a {
  text-decoration: none;
  cursor: pointer;
}

.section-container {
  max-width: 1140px;
  margin: 0 auto;

  @media (max-width: 720px) {
    max-width: 800px;
  }
}
