.activeTable,
.inactiveTable {
  border-radius: 2px;
  padding: 9px;
}

.activeTable {
  background-color: rgba(133, 196, 67, 0.25);
}

.inactiveTable {
  background-color: rgba(249, 107, 107, 0.25);
}
