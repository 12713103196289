@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

button:focus { outline: none}

.whiteIcon {
  color: white;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

html,
body {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px !important;
  background-color: #f4f7fd;
}

h5 {
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
}

.form-control2 {
  margin-top: 1rem;
  max-width: 400px;
}

select #range {
  max-width: 400px;
}

#Time-Hr,
#Time-Min {
  max-width: 200px;
}

.dashboardItems {
  grid-gap: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(243px, 3fr));
}

.content {
  max-width: 1140px;
  width: 100%;
  justify-content: center;
  padding: 0 15px;
}

.container-fluid {
  display: flex;
  flex: 1;
  justify-content: center;
}

.branding-logo {
  max-height: 3rem;
}

.loaderSpinner {
  height: 100%;
  text-align: center;
}

.notificationsHolder {
  width: 500px;
  margin: 40px auto;
}

.notificationBox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  width: 100vw;
}

.pageHolder {
  border-radius: 15px 15px 15px 15px;
  -moz-border-radius: 15px 15px 15px 15px;
  -webkit-border-radius: 15px 15px 15px 15px;
  border: 1px solid #0137c9;
  padding: 10px;
}

/*bootsrap mods*/

.h2,
h2 {
  font-size: xx-large;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.h3,
h3 {
  font-size: xx-large;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.h6,
h6 {
  font-family: "Montserrat", sans-serif;
}

.bg-light {
  background-color: #1d57d0 !important;
}

.navbar {
  padding: 0;
}

#profile-navbar-nav,
.navbar-nav,
.navbar-nav a {
  height: 100%;
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #ffffff !important;
  background-color: rgba(61, 145, 194, 0.25);
}

.helpLink:hover {
  color: #ffffff !important;
  background-color: rgba(61, 145, 194, 0.25);
  border-radius: 4px;
}

.userDropdown>a {
  border-radius: 4px;
}

.helpLink {
  height: 40px;
  width: 40px;
}

.helpLink {
  display: grid;
  place-content: center;
}

.loggedInAs {
  border-left: thin solid #ffffff;
  padding-left: 0.5em;
}

.form-card-body {
  padding-left: 1.5em;
}

.btn {
  margin-left: 15px !important;
}

.btn-outline-primary {
  border: 1px solid rgba(114, 117, 126, 0.25);
  color: #1d57d0;
}

.btn-outline-primary:hover {
  background-color: #1d57d0;
}

.btn.btn-link {
  margin-left: 0px !important;
  padding-left: 0px !important;
  font-size: 1em !important;
}

label {
  color: #5b6688;
  font-size: 1em !important;
}

.btn-primary {
  background-color: #1d57d0 !important;
}

/* .btn-primary:hover {
  background-color:#0943BC !important;
} */

.form-control {
  border: 1px solid rgba(114, 117, 126, 0.25);
  border-radius: 4px;
  background-color: #ffffff;
}

legend {
  text-align: left;
  font-size: x-large;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.activeTR {
  background-color: lightskyblue;
}

.navbar-light .navbar-brand {
  color: #ffffff;
}

.dashboard_item {
  max-width: 350px;
  max-height: 300px;
  padding: 24px;

  text-align: center;

  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 9px 16px 0 rgba(0, 0, 0, 0.04);
}

.dashboard_item a {
  color: #2b2b36;
}

.whiteBox {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
  padding: 24px 12px;
}

.breadcrumb {
  background-color: #ffffff !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
}

hr {
  margin-top: 0px;
  margin-bottom: 0px;
}

.searchForm {
  padding-top: 1em;
  padding-bottom: 1em;
}

.tableHr {
  padding-left: 0.4em;
}

form.whiteBox {
  padding: 1em;
}

.tabContent {
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.wrapper {
  display: flex;
  width: 100%;
}

#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: #7386d5;
  color: #fff;
  transition: all 0.3s;
}

.form-control2 input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .vipSidebar {
  width: 25%;
}

#root > div > div.vipSidebar > div > div:nth-child(4) > div {
  width: 100%;
} */

.full-width {
  width: 100% !important;
}

/* swiitch stuff */

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: inline-block;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 2em;
  height: 1em;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 0.2em;
  left: 0.2em;
  width: 0.6em;
  height: 0.6em;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

/* emd switch stuff */

.transparent {
  background-color: transparent !important;
  box-shadow: inset 0px 1px 0 rgba(0, 0, 0, 0.075);
}

.left-border-none {
  border-left: none !important;
  box-shadow: inset 0px 1px 0 rgba(0, 0, 0, 0.075);
}

.searchPadding {
  white-space: nowrap;
  text-align: center;
  margin-left: 10px;
}

.serchIcon {
  background-image: url(ss.png);
  background-position: 100% 7px;
  background-repeat: no-repeat;
}

.head-left {
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
}

.head-left button {
  padding-left: 0px;
}

.head-center {
  text-align: center;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}

.react-datepicker-wrapper {
  display: block !important;
}

.paddedTop {
  padding-top: 5px !important;
}

.hidden {
  display: none;
}

.noleft>.btn,
.noleft {
  margin-left: 0px !important;
}

.btn-group>.btn {
  margin-left: 0px !important;
}

/* .input-group > .btn {
  margin-left: 0px !important;
} */

.input-group-append>.btn {
  margin-left: 0px !important;
}

.input-group-preappend>.btn {
  margin-left: 0px !important;
}

.text-black {
  color: #212529;
}

.bottomPad {
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.typeahead-options div {
  font-size: 2em;
  border-bottom: 1px solid #1d57d0;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 3px;
}

.quickcustommargin {
  margin-left: 0;
  margin-bottom: -1px;
}

.quickcustommargin>.active {
  border-bottom: 1px solid white !important;
  z-index: 10;
}

.anothercustomdiv span,
.anothercustomdiv .btn {
  max-height: 38px;
}

.customLabel {
  min-width: 137px;
}

.customLabel input {
  margin-right: 10px;
}

.numbersButton {
  max-height: 38px;
}

.spacerSpan {
  margin-left: 15px;
}

.customHeight {
  max-height: 38px;
  margin-bottom: 0.3rem;
}

.divider {
  border-right: 1px solid #e6e8f0;
  height: 32px;
  margin-left: 8px;
  margin-right: 8px;
}


#recordings_email_button {
  margin-left: 30px !important;
}