.btn-group {
  width: 100%;
  // margin: -10px !important;
}

.dropdown-toggle.btn.btn-link {
  padding: 4px 0 4px 24px !important;

  display: flex;
  justify-content: space-between;
}

.btn.btn-link::after {
  margin: auto 0.5em;
}

.dropdown-menu {
  padding: 0 !important;
}

.btn.btn-link {
  padding: 8px 16px !important;
}

.topMenuNavDropdown > a {
  height: 100%;
}

.topMenuNavLink,
.topMenuNavDropdown > a {
  margin-right: 16px;
  line-height: 56px;
}

nav.navbar {
  height: 72px;
}

.navDropdownButton:hover > button {
  text-decoration: none;
  color: #2b2b36;
  background-color: #f8f9fa;
}

.navDropdownButton > button {
  text-decoration: none;
  color: #2b2b36;
}

// .dropdown-menu.show {
//   max-width: 10em;
//   transform: translateX(-1.5em);
// }
