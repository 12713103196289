.breadcrumb-link > a,
li.breadcrumb-item.breadcrumb-home > a {
  color: inherit;
}

.breadcrumb-item.active::before,
.breadcrumb-item.breadcrumb-link::before {
  transform: scale(0.6);
  position: relative;
  top: 3px;

  content: url("data:image/svg+xml,%3C!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='13.8px' height='23.3px' viewBox='0 0 13.8 23.3' style='overflow:visible;enable-background:new 0 0 13.8 23.3;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2372757E;%7D%0A%3C/style%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Layer_2_2_'%3E%3Cpolygon class='st0' points='2.5,0 0,2.4 9.3,11.8 0,20.9 2.5,23.3 13.8,11.8 '/%3E%3C/g%3E%3C/svg%3E%0A");
}

ol.breadcrumb > li.breadcrumb-item:nth-child(1)::before {
  display: none;
}

.breadcrumb {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: #2b2b36 !important;
}

.breadcrumb-item.active {
  color: #5b6688 !important;
}

li.breadcrumb-item.breadcrumb-home > a {
  font-size: 34px;
  line-height: 52px;
  font-weight: 600;
}

ol.breadcrumb {
  background-color: #f4f7fd !important;
}

.margin40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
