.wizard__offer {
  margin: 50px 0;

  background-color: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  min-width: 1000px;
  @media (max-width: 600px) {
    padding-top: 0px;
    min-width: 400px;
    // flex-wrap: wrap;
  }

  &-right {
    margin: 0 auto;
    max-width: 600px;
  }
  &-post {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-img {
      padding-top: 58px;
      margin: 0 auto;
    }
    &-box {
      padding-top: 76px;
      padding-bottom: 76px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-text {
        line-height: 1.5em;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.wizard6__offer-post-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  height: 104px;
  /*width: 630px;*/
  border: 1px dashed rgba(114, 117, 126, 0.25);
  border-radius: 4px;
}

.wizard6-box-middle {
  width: 1px;
  border: 1px dashed rgba(114, 117, 126, 0.25);
  border-radius: 4px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 0px;
  margin: 0;
}

.wizard6-box-middle::before {
  content: "or";
  background-color: white;
  margin-left: -6px;
  height: 28px;
  padding: 0px;
  font-size: 1.6em;
}

.wizard6__offer-p {
  height: 32px;
  color: #2b2b36;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}

.wizard6__offer-post {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.wizard6__plus-icon {
  background-image: url(../pages/NewCustomerWizard/images/add-icon.png);
  background-size: 24px 24px;
  background-repeat: no-repeat;
  color: white;
  background-position: center center;
  margin-right: 10px;
}
