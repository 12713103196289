.sidebarBody {
  background-color: #ffffff;
  padding-left: 29px;
  padding-right: 29px;
  max-width: 320px;
  max-height: 100%;
  min-width: 270px;

  margin-right: 15px;
  margin-left: -15px;

  flex: 1;
}

.sidebar .card-header,
.sidebar .card {
  border: none !important;
}

.sidebarHead {
  margin-top: 36px;
  text-align: center;
}

.sidebarHeading {
  font-family: "Montserrat";
  margin-left: auto;
  margin-right: auto;
  font-size: 22px;
}

.sidebarHead > a {
  font-size: 14px !important;
  margin: 32px auto 16px auto !important;
  padding: 8px;
  line-height: 24px;
}

.sidebarUp::before {
  position: relative;
  right: 7px;

  content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' x='0px' y='0px' viewBox='0 0 12 8' style='enable-background:new 0 0 12 8;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%232B2B36;%7D .st1%7Bfill:none;%7D%0A%3C/style%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1-Copy'%3E%3Cg id='Customer---Real-Time-Stats-ALT' transform='translate(-32.000000, -295.000000)'%3E%3Cg id='Group-6' transform='translate(0.000000, 72.000000)'%3E%3Cg id='Accordion-Header' transform='translate(26.000000, 214.000000)'%3E%3Cg id='baseline-expand_x5F_less-24px-_x28_1_x29_-copy' transform='translate(12.000000, 13.000000) scale(1, -1) translate(-12.000000, -13.000000) translate(0.000000, 1.000000)'%3E%3Cpolygon id='Path' class='st0' points='12,8 6,14 7.4,15.4 12,10.8 16.6,15.4 18,14 '/%3E%3Cpolygon id='Path_1_' class='st1' points='0,0 24,0 24,24 0,24 '/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.sidebarDown::before {
  position: relative;
  right: 7px;

  content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' transform='rotate(180)' width='12px' x='0px' y='0px' viewBox='0 0 12 8' style='enable-background:new 0 0 12 8;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%232B2B36;%7D .st1%7Bfill:none;%7D%0A%3C/style%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Page-1-Copy'%3E%3Cg id='Customer---Real-Time-Stats-ALT' transform='translate(-32.000000, -295.000000)'%3E%3Cg id='Group-6' transform='translate(0.000000, 72.000000)'%3E%3Cg id='Accordion-Header' transform='translate(26.000000, 214.000000)'%3E%3Cg id='baseline-expand_x5F_less-24px-_x28_1_x29_-copy' transform='translate(12.000000, 13.000000) scale(1, -1) translate(-12.000000, -13.000000) translate(0.000000, 1.000000)'%3E%3Cpolygon id='Path' class='st0' points='12,8 6,14 7.4,15.4 12,10.8 16.6,15.4 18,14 '/%3E%3Cpolygon id='Path_1_' class='st1' points='0,0 24,0 24,24 0,24 '/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.sidebarBody > .sidebar > .card > .card-header {
  background-color: inherit;
  padding-left: 10px;
  padding-right: 0;
  padding-top: 19px;
  padding-bottom: 19px;

  cursor: pointer;

  font-size: 16px;
  line-height: 26px;
}
.sidebarBody > .sidebar > .card {
  border-top: 1px solid rgba(114, 117, 126, 0.1) !important;
}
.sidebarBody > .sidebar > .card:last-child {
  border-bottom: 1px solid rgba(114, 117, 126, 0.1) !important;
}

.sidebarSwitchDiv > label {
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 26px;
}

.react-switch {
  position: relative;
  top: 3px;
}

.sidebarSwitchDiv {
  display: flex;
  justify-content: space-between;
  padding: 23px 3px 20px 3px !important;
}

.sidebarDiv {
  padding-right: 3px;
  padding-left: 3px;
}

.sidebarDivServices:not(:last-child),
.sidebarLimitsDivider {
  border-bottom: 1px solid rgba(114, 117, 126, 0.1) !important;
  margin-bottom: 16px;
}

.sidebarServiceName {
  color: #1d57d0;

  font-size: 16px;
  font-weight: 500;
}

.sidebarServiceStatus {
  height: 26px;
  white-space:nowrap;
  color: #5b6688;
  font-family: Roboto;
  font-size: 16px;

  text-align: right;
}

.sidebarServiceStatus {
  font-size: 16px;
}

.sidebarTitle {
  font-size: 14px;
  color: #5b6688;
  letter-spacing: 0;
  line-height: 26px;
}

.sidebarText,
.sidebarGreyText {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 8px;
}
.sidebarGreyText {
  color: #5b6688;
}

.sidebarSubButton {
  font-size: 14px;
  width: 100% !important;
  margin: 16px 0 !important;
  line-height: 24px;
}

.sidebarFormButton {
  font-size: 14px;
}

.sidebarLimitsDivider input {
  margin-bottom: 16px;
}
